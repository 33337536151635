import '../Css/footer.css'

const Footer = () => {
    return (
        <footer>
            <strong>Mohamad Mourad</strong>
            <p>Copyright &copy; {new Date().getFullYear()}</p>
        </footer>
    );
}
 
export default Footer;